import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/Footer";
import FooterLinks from "../components/FooterLinks";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import { push, ref, db, set, onValue } from "../config/firebase";
import Navbar from "../components/Navbar";
import JoditEditor from "jodit-react";
import Chat from "../components/Chat";

const CreateOrder = () => {
  const { user, loading } = useUserAuth();
  const [orders, setOrders] = useState([]);
  const [btnloading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState({});
  const [services, setServices] = useState([]);
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");

  const editor = useRef(null);
  const [content, setContent] = useState("Description");
  const config = {
    readonly: false,
    height: 300,
  };
  const handleUpdate = (event) => {
    setDescription(event);
  };

  useEffect(() => {
    console.log(location.state);

    if (user.firstname && user.lastname && user.email) {
      setFirstname(user.firstname);
      setLastname(user.lastname);
      setEmail(user.email);
    }

    onValue(ref(db, "services"), (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const propertyValues = Object.values(data);

        setServices(propertyValues);
      }
    });

    if (location.state.key) {
      console.log(location.state.key);
      const serviceref = ref(db, "services/" + location.state.key);
      onValue(serviceref, (snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.val();
          console.log("here");
          console.log(data);
          setService(data);
        }
      });
    }
  }, [user]);

  const serviceChange = (id) => {
    console.log(id);
    const service = services.find((service) => service.id == id);
    console.log(service);
    setService(service);
  };

  const submit = () => {
    if (
      firstname === "" ||
      lastname === "" ||
      email === "" ||
      service === "" ||
      date === "" ||
      description === ""
    ) {
      alert("Please Fill The Complete Form");
    } else {
      setBtnLoading(true);
      const orderref = ref(db, "orders/");

      console.log(service);

      let obj = {
        key: push(orderref).key,
        date: date,
        firstname: firstname,
        lastname: lastname,
        email: email,
        serviceid: service.key,
        servicename: service.title,
        description: description,
        userid: user.uid,
        status: "Requested",
        amount: "Requested",
        paymentstatus: "Pending",
        workstatus: "Pending",
        work: null,
        review: false,
      };

      console.log(obj);

      set(ref(db, "orders/" + obj.key), obj)
        .then(() => {
          // Data saved successfully!
          alert("Order Requested successfully!");
          navigate("/myorders");
          setBtnLoading(false);
        })
        .catch((error) => {
          // The write failed...
          alert("Order failed!");
          setBtnLoading(false);
        });
    }
  };
  if (loading) {
    return (
      <div style={{ backgroundColor: "#333", height: "100vh" }}>
        <h1 className="text-center text-white" style={{ paddingTop: "30%" }}>
          Loading...
        </h1>
      </div>
    );
  } else if (loading === false && user.uid) {
    return (
      <>
        {/* Navbar Component*/}
        <Navbar />
        <section
          className="section bg-home home-half"
          id="home"
          data-image-src="images/bg-home.jpg"
        >
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2 text-white text-center">
                    <h1 className="home-title">New Order</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-pattern-effect">
            <img src="images/bg-pattern.png" alt="" />
          </div>
        </section>
        <br />
        <br />
        <section>
          <div className="container">
            <h1 className="text-center">Create New Order</h1>
            <br />
            <div>
              <div className="row">
                <div className="col-md-12">
                  <label>Select The Service</label>
                  <select
                    className="form-control"
                    value={service.key}
                    onChange={(e) => serviceChange(e.target.value)}
                  >
                    <option value="">Select The Service</option>
                    {services.map((service) => (
                      <option key={service.key} value={service.key}>
                        {service.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>Enter First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter First Name"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label>Enter Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>Enter Delivery Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Enter Deliver Date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label>Enter Email Address</label>

                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label>Enter Project Description</label>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    onBlur={handleUpdate}
                    onChange={(newContent) => {}}
                  />
                  {/* <textarea
                    style={{
                      width: "100%",
                      minHeight: "200px",
                      maxHeight: "auto",
                    }}
                    className="form-control"
                    placeholder="Enter Your Project Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea> */}
                </div>
              </div>

              <br />
              <br />
              <center>
                <button
                  className="btn btn-custom"
                  onClick={submit}
                  disabled={btnloading}
                >
                  Confirm Order
                </button>
              </center>
              <br />
              <br />
            </div>
          </div>
        </section>

        {/* Footer Component*/}
        <Footer />

        {/* FooterLinks Component*/}
        <FooterLinks />
          {/* Chat Component*/}
    <Chat />
      </>
    );
  } else if (
    loading === false &&
    (user.uid === null || user.uid === undefined)
  ) {
    return <Navigate to="/login" />;
  }
};
export default CreateOrder;
