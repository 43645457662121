import React from "react";
import Navbar from "../components/Navbar";
import FooterLinks from "../components/FooterLinks";
import AboutUs from "../components/AboutUs";
import EventBox from "../components/EventBox";
import Contact from "../components/Contact";
import Chat from "../components/Chat";

const Events = () => {
  return (
    <>
      {/* Navbar Component*/}
      <Navbar activePage="Events" />

      <section id="mainHome" className="mainHomeAbout">
        <div className="container">
          <div className="row mainRow mainRowAbout">
            <div className="col-lg-6 centerCols">
              <div className="w-100">
                <h1 className="mainTitle">Events</h1>
                <p className="mainPara">
                  Welcome to our Events page, where we bring you the latest
                  happenings and exciting activities. Whether you're looking for
                  upcoming workshops, community gatherings, or special
                  celebrations, this is the place to stay informed. Our events
                  are designed to inspire, engage, and connect people from all
                  walks of life. Be sure to check back regularly for updates on
                  new events, and don't miss out on the opportunity to
                  participate in memorable experiences. We look forward to
                  seeing you at our next event!
                </p>
              </div>
            </div>
            <div className="col-lg-6 centerCols centerColsAbout">
              <img
                className="mainGifAbout"
                src="images/Gifs/eventsGif.gif"
              ></img>
            </div>
          </div>
        </div>
        <div id="circle1"></div>
        <div id="circle2"></div>
      </section>

      {/* EventBox Component*/}
      <EventBox />

      {/* Contact Component*/}
      {/* <Contact /> */}

      {/* FooterLinks Component*/}
      <FooterLinks />
      {/* Chat Component*/}
      <Chat />
    </>
  );
};

export default Events;
