import React from "react";

const Testi = ({ comments }) => {
  console.log(comments);
  return (
    <section className="section mt-4" id="testi">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <p className="section-title-small text-center">HAVE A LOOK ON</p>
            <h1 className="section-title-main text-center">
              What They’ve Said
            </h1>
            <p className="section-subtitle text-light text-center font-secondary padding-t-30">
              We could go on and on regarding why we're the best fit to create a
              cutting-edge website. However, we'll let you browse through all
              the testimonials that back up this claim.
            </p>
          </div>
        </div>
        {comments.map((comment, i) => {
          return (
            <div className="row mt-5">
              <div
                className={
                  comment?.side === "right"
                    ? "col-12 centerColBetween yellow mobileCenter"
                    : "col-12 centerColBetween red left mobileCenter"
                }
              >
                <img
                  src={comment?.image}
                  className={
                    comment?.side === "right"
                      ? "testiImg d-md-none"
                      : "testiImg"
                  }
                ></img>
                <div
                  className="testiBox flex-direction-column"
                  style={{ flexDirection: "column" }}
                >
                  <p dangerouslySetInnerHTML={{ __html: comment?.message }}></p>
                  <br />
                  <p>* {comment?.name}</p>
                </div>
                {comment?.side === "right" ? (
                  <img
                    src={comment?.image}
                    className={
                      comment?.side === "right"
                        ? "testiImg hideMobile"
                        : "testiImg d-md-none"
                    }
                  ></img>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        })}
        {/* <div className="row mt-5">
          <div className="col-12 centerColBetween yellow mobileCenter">
            <img
              src="images/testimonials/user2.jpeg"
              className="testiImg d-md-none"
            ></img>
            <div className="testiBox">
              <p>
                “I recently had the pleasure of working with Akili and I must
                say, it was a fantastic experience. From the very beginning,
                they showed an exceptional level of professionalism,
                communication and attention to detail that really set them apart
                from the rest..” <br />
                <br />* Nyota
              </p>
            </div>
            <img
              src="images/testimonials/user2.jpeg"
              className="testiImg hideMobile"
            ></img>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 centerColBetween red left mobileCenter">
            <img
              src="images/testimonials/user1.jpeg"
              className="testiImg"
            ></img>
            <div className="testiBox">
              <p>
                “Excelente servicio, me sirvió mucho para mis proyectos
                personales y laborales.
                <br />
                Recomendado ! .” <br />
                <br />* Daviana Moralez
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 centerColBetween yellow mobileCenter">
            <img
              src="images/testimonials/user3.jpeg"
              className="testiImg d-md-none"
            ></img>
            <div className="testiBox">
              <p>
                {" "}
                “Je suis étudiante en première année de licence en infographie
                et web design. Je suis tombée sur les publications de BLKTECH
                sur les réseaux sociaux et j'avoue que cela m'est d'une grande
                aide. Les quiz et astuces partagés me sont très utiles dans mon
                apprentissage. Merci beaucoup..” <br />
                <br />* Ouro ébène
              </p>
            </div>
            <img
              src="images/testimonials/user3.jpeg"
              className="testiImg hideMobile"
            ></img>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Testi;
