import React from "react";
import Navbar from "../components/Navbar";
import Services from "../components/Services";
import Contact from "../components/Contact";
import FooterLinks from "../components/FooterLinks";
import { useNavigate } from "react-router-dom";
import Chat from "../components/Chat";

const ServicesPage = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Navbar Component*/}
      <Navbar activePage = "Services"/>

      <section id="mainHome">
        <div className="container">
          <div className="row mainRow">
            <div className="col-lg-6 centerCols">
              <div className="w-100">
              <h1 className="mainTitle">We got everything <br/>you need to kickstart</h1>
              <p className="mainPara">
              Welcome to BLKtech, your one-stop destination for all your digital needs. Whether you're looking to build a stunning website, develop cutting-edge mobile applications, design captivating logos, or create eye-catching banners, we've got you covered. Our team of skilled professionals is dedicated to bringing your vision to life with their expertise and creativity. With a deep understanding of the latest industry trends and technologies, we ensure that your digital presence is not just impactful but also future-proof. At BLKtech, we strive to deliver top-notch solutions that combine aesthetics, functionality, and user experience. Let us help you make a lasting impression in the digital world. Partner with BLKtech and take your online presence to new heights.
              </p>
              </div>
            </div>
            <div className="col-lg-6 centerCols">
              <img className="mainGifService" src="images/Gifs/serviceMain.gif"></img>
            </div>
          </div>
        </div>
        <div id="circle1"></div>
        <div id="circle2"></div>
      </section>


      {/* Services Component*/}
      <Services />

      {/* Contact Component*/}
      <Contact />

      {/* FooterLinks Component*/}
      <FooterLinks />
        {/* Chat Component*/}
    <Chat />
    </>
  );
};

export default ServicesPage;
