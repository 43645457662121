import React, { useState } from "react";
import { set, ref, db, push } from "../config/firebase";
import axios from "axios";
import logos from "../images/logos.png";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  const submit = async (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      message,
      subject,
    };
    const contactRef = ref(db, "messages/");
    const key = push(contactRef).key;
    data.key = key;
    set(ref(db, "messages/" + key), data).then(() => {
      axios.post(
        "https://us-central1-webtech-services.cloudfunctions.net/webApi/api/v1/sendmessage",
        {
          name,
          email,
          message,
          subject,
        }
      );
    });

    alert("Message sent successfully!");
    setName("");
    setEmail("");
    setMessage("");
    setSubject("");
  };

  return (
    <section className="section" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-12 d-md-flex">
            <h1 className="mobileSmallText">Leave a message and we will <br/>get back to you soon!</h1>
            <img src={logos} className="contactLogo"></img>
          </div>
          <div className="col-lg-7">
            <div className="custom-form mt-4 pt-4">
              <div id="message"></div>
              <form name="contact-form" id="contact-form" onSubmit={submit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mt-2">
                      <input
                        name="name"
                        id="name"
                        type="text"
                        className="form-control"
                        placeholder="Your name*"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mt-2">
                      <input
                        name="email"
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="Your email*"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-2">
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        placeholder="Your Subject.."
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-2">
                      <textarea
                        name="comments"
                        id="comments"
                        rows="4"
                        className="form-control"
                        placeholder="Your message..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 text-left">
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      className="submitBnt btn btn-custom"
                      value="Send Message"
                    />
                    <div id="simple-msg"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-5 pl-5">
            <div className="mt-4 pt-4">
              <p className="mt-4">
                <span className="h5">Email Address </span>
                <br />{" "}
                <span className="text-muted d-block mt-2">
                  infocontact@webdeveloperblk.com
                </span>
              </p>
              <p className="mt-4">
                <span className="h5">Office Address:</span>
                <br />{" "}
                <span className="text-muted d-block mt-2">
                  Atlanta <br />
                  Georgia
                </span>
              </p>
              <p className="mt-4">
                <span className="h5">Working Hours:</span>
                <br />{" "}
                <span className="text-muted d-block mt-2">
                  Monday - Saturday <br />
                  From 9:00AM To 6:00PM
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Contact;
