import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onValue, ref, db } from "../config/firebase";

const EventBox = (props) => {
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);

  const submit = () => {
    if (props.loc === "services") {
      navigate("/createorder", { state: { key: props.servicekey } });
    } else {
      navigate("/services");
    }
  };

  useEffect(() => {
    onValue(ref(db, "events"), (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const propertyValues = Object.values(data);

        setEvents(propertyValues);
      }
    });
  }, []);

  return (
    <section className="section">
      <div className="container">
        <div className="row">
          {events.map((event, i) => {
            return (
              <div className="col-md-6 col-lg-4" key={i}>
                <div className="eventBox">
                  <img src={event?.thumbnail}></img>
                  <h4>{event?.title}</h4>
                  {/* <p>Lorem ipsum dolor sit amet consectetur. Quis ultrices pulvinar dui at. Erat est eu iaculis consectetur sit congue nunc urna. At eros iaculis vitae vitae sapien tellus ut.</p>
                <button className="btn viewMoreBtn">View Details ></button> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default EventBox;
