import React from "react";
import { useNavigate } from "react-router-dom";

const Process = (props) => {
  const navigate = useNavigate();

  const submit = () => {
    if (props.loc === "services") {
      navigate("/createorder", { state: { key: props.servicekey } });
    } else {
      navigate("/services");
    }
  };
  return (
    <section className="section pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <p className="section-title-small text-center">GET TO KNOW OUR</p>
            <h1 className="section-title-main text-center">Work Process</h1>
            <p className="section-subtitle text-light text-center font-secondary padding-t-30">
              This website wouldn't exist in an ideal world if clients
              understood the importance of having a web copy before the design
              started. And since it's not, rely on us for all your developing
              and designing needs. <br />
              <br />
              Our process is simple. We listen before we design. We refine
              because we’re never satisfied until you aren’t. We test, hone,
              customize and repeat to ensure we meet your digital expectations.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 tabRow">
            <div className="workCon">
              <div className="workBox">
                <img src="images/Gifs/work1.gif"></img>
                <p>Tell us what <br/>you need</p>
              </div>
              <img className="playIcon" src="images/Icons/play.png"></img>
              <div className="workBox">
                <img src="images/Gifs/work2.gif"></img>
                <p>Get free <br/>quotes</p>
              </div>
              <img className="playIcon" src="images/Icons/play.png"></img>
              <div className="workBox workBox3">
                <img src="images/Gifs/work3.gif"></img>
                <p>Deliver high <br/>quality product</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="text-center mx-auto">
            <button
              onClick={submit}
              className="btn btn-custom waves-light waves-effect inquiryBtn mt-5"
            >
              Get Your Project Started >
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Process;
