import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Contact from "../components/Contact";
import FooterLinks from "../components/FooterLinks";
import { useLocation, useNavigate } from "react-router-dom";
import Process from "../components/Process";
import { Modal } from "react-bootstrap";
import StarRatingComponent from "react-star-rating-component";
import Chat from "../components/Chat";

import {
  onValue,
  ref,
  db,
  push,
  set,
  query,
  storage,
  getDownloadURL,
  firebaseStorageRef,
  uploadBytesResumable,
} from "../config/firebase";

const ServiceDetails = () => {
  const [service, setService] = useState({});
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState({});

  const [reviewShow, setReviewShow] = useState(false);
  const [company, setCompany] = useState("");
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(1);
  const [country, setCountry] = useState("");
  const [image, setImage] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [progresspercent, setProgresspercent] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state.key) {
      console.log(location.state.key);
      const serviceref = ref(db, "services/" + location.state.key);

      onValue(serviceref, (snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.val();
          console.log(data);
          setService(data);
        }
      });

      const reviewsRef = ref(db, "reviews/" + location.state.key);

      onValue(reviewsRef, (snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.val();
          const propertyByValue = Object.values(data);
          console.log(propertyByValue);
          setReviews(propertyByValue);
        } else {
          setReviews([]);
        }
      });
    } else {
      navigate("/services");
    }
  }, [location]);

  const handleSubmit = (e) => {
    console.log(e);
    const file = e.target.files[0];
    if (!file) return;
    const storageRef = firebaseStorageRef(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImage(downloadURL);
        });
      }
    );
  };

  const addReview = () => {
    const obj = {
      image,
      review,
      rating,
      company,
      country,
    };
    console.log(obj);
    if (
      image === "" ||
      review === "" ||
      rating === "" ||
      company === "" ||
      country === ""
    ) {
      alert("Please Fill All Details");
    } else {
      const reviewRef = ref(db, "reviews/" + location.state.key);

      let o = {
        company: company,
        rating: rating,
        review: review,
        name: firstname + " " + lastname,
        currentService: location.state.key,
        image: image,
        country: country,
        key: push(reviewRef).key,
      };
      set(ref(db, "reviews/" + location.state.key + "/" + o.key), o).then(
        () => {
          setReviewShow(false);
          setCompany("");
          setReview("");
          setRating(1);
          setCountry("");
          setImage("");
          setFirstName("");
          setLastName("");
        }
      );
    }
  };
  return (
    <>
      {/* Navbar Component*/}
      <Navbar activePage = "Services"/>

      <section id="mainHome">
        <div className="container">
          <div className="row mainRow">
            <div className="col-lg-6 centerCols">
              <div className="w-100">
              <h1 className="mainTitle">{service.title}</h1>
              <p className="mainPara" dangerouslySetInnerHTML={{ __html: service.longDescription }} />
              </div>
            </div>
            <div className="col-lg-6 centerCols">
              {service.title=="Web Development" ? 
                <img src="images/Gifs/webDev.gif" className="mainGifService"></img>
                :(service.title=="Web Designing" ? 
                <img src="images/Gifs/webDesign.gif" className="mainGifService"></img>
                :(service.title=="Mobile Application Development" ? 
                <img src="images/Gifs/mobileDev.gif" className="mainGifService"></img>
                :null
                )
                )}
            </div>
          </div>
        </div>
        <div id="circle1"></div>
        <div id="circle2"></div>
      </section>

      <section>
        <div className="section container pt-0">

          <Process loc="services" servicekey={service.key} />

          {reviews.length > 0 ? (
            <>
              <br />

              <p className="section-title-small text-center mt-5">COMMENTS</p>
              <h1 className="section-title-main text-center">About Services</h1>
              <center>
                {" "}
                <button
                  className="btn viewServiceBtn mt-3"
                  onClick={() => setReviewShow(true)}
                >
                  Add Review
                </button>
              </center>
              <div class="wrapper">
                {reviews.map((review, index) => {
                  return (
                    <div class="box reviewBox" key={index}>
                      <i class="fas fa-quote-left quote"></i>
                      <p>{review.review}</p>
                      <div class="content">
                        <div class="info">
                          <div class="name">{review.name}</div>
                          <div class="job">{review.company}</div>
                          <div class="name">{review.country}</div>

                          <div class="stars">
                            {review.rating === 5 ? (
                              <>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                              </>
                            ) : review.rating === 4 ? (
                              <>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                              </>
                            ) : review.rating === 3 ? (
                              <>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>

                                <i class="far fa-star"></i>
                              </>
                            ) : review.rating === 2 ? (
                              <>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star">
                                  <i class="far fa-star"></i>
                                  <i class="far fa-star"></i>
                                  <i class="far fa-star"></i>
                                </i>
                              </>
                            ) : review.rating === 1 ? (
                              <>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </>
                            ) : (
                              <>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </>
                            )}
                          </div>
                        </div>
                        <div class="image">
                          <img src={review.image} alt="" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )}

          <br />
        </div>
        <Modal
          size="lg"
          show={reviewShow}
          onHide={() => setReviewShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Add Review
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <center>
                    {image ? (
                      <>
                        <img
                          src={image}
                          style={{
                            height: "200px",
                            width: "200px",
                            borderRadius: "25px",
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </center>
                  <label>Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleSubmit(e)}
                  />
                  {progresspercent > 0 ? (
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: progresspercent + "%" }}
                        aria-valuenow={progresspercent}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {progresspercent}%
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Company Name Or Individual Work Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <input
                    type="text"
                    className="form-control"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Rating</label>
                  <div style={{ marginTop: "20px" }}>
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      value={rating}
                      onStarClick={(nextValue) => setRating(nextValue)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Review</label>
                  <textarea
                    className="form-control"
                    rows="5"
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <button
                    className="btn addReviewBtn btn-block"
                    onClick={addReview}
                  >
                    Add Review
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </section>

      {/* Contact Component*/}
      <Contact />

      {/* FooterLinks Component*/}
      <FooterLinks />
        {/* Chat Component*/}
    <Chat />
    </>
  );
};

export default ServiceDetails;
