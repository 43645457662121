import React from "react";
import Footer from "../components/Footer";
import FooterLinks from "../components/FooterLinks";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import Chat from "../components/Chat";

const ContactUs = () => {
  return (
    <>
      {/* Navbar Component*/}
      <Navbar />
      <section
        className="section bg-home home-half"
        id="home"
        data-image-src="images/bg-home.jpg"
      >
        <div className="bg-overlay"></div>
        <div className="display-table">
          <div className="display-table-cell">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2 text-white text-center">
                  <h1 className="home-title">Contact Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-pattern-effect">
          <img src="images/bg-pattern.png" alt="" />
        </div>
      </section>

      <br />
      <br />
      <br />
      <section className="section bg-light">
        <div className="container">
          <Contact />
        </div>
      </section>

      {/* Footer Component*/}
      <Footer />

      {/* FooterLinks Component*/}
      <FooterLinks />
      {/* Chat Component*/}
      <Chat />
    </>
  );
};

export default ContactUs;
