import React from "react";
import { useNavigate } from "react-router-dom";

const Process = (props) => {
  const navigate = useNavigate();

  const submit = () => {
    if (props.loc === "services") {
      navigate("/createorder", { state: { key: props.servicekey } });
    } else {
      navigate("/services");
    }
  };
  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <p className="section-title-small text-center">GET TO KNOW</p>
            <h1 className="section-title-main text-center">What We Do</h1>
            <p className="smallBlueText">After all, details matter most.</p>
            <p className="section-subtitle text-light text-center font-secondary padding-t-30">
            I specialize in developing and designing websites for individuals and start-ups by providing customized templates and website designs – allowing them to break free from the shackles of cheap solutions and generic templates. Your website is your brand’s first impression, so make sure it’s a good one! I’ll help you achieve just that. <br/><br/>When I am not coding or designing, I take a break by doing skydiving or equestrian activities. I believe that it's essential to recharge my batteries to get back to work. Remember that your website is the first impression of your brand. It's crucial to make it a good one. I can help you achieve this goal.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="text-center mx-auto">
            <button
              onClick={submit}
              className="btn btn-custom waves-light waves-effect inquiryBtn mt-5"
            >
              Get Your Project Started >
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Process;
