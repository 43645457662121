import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import logos from "../images/logos.png";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const navigate = useNavigate();

  const { createAccount, loading } = useUserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(email, password, firstname, lastname);

    await createAccount(email, password, firstname, lastname);

    navigate("/");
  };
  return (
    <>
      <div className="account-home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="mdi mdi-home h1"></i>
        </Link>
      </div>

      <section className="bg-account-pages height-100vh">
        <div className="display-table">
          <div className="display-table-cell">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="card account-card">
                    <div className="card-body">
                      <div className="text-center mt-3">
                        <h3 className="font-weight-bold">
                          <Link
                            to="/signup"
                            className="text-dark text-uppercase account-pages-logo"
                          >
                            <img src={logos} style={{ height: "100px" }} />
                          </Link>
                        </h3>
                        <p className="text-muted">Create New Account</p>
                      </div>
                      <div className="p-3">
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label for="username">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="firstname"
                              placeholder="Enter First Name"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                            />
                          </div>

                          <div className="form-group">
                            <label for="username">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastname"
                              placeholder="Enter Last Name"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                            />
                          </div>

                          <div className="form-group">
                            <label for="username">Email Address</label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              placeholder="Enter Email Address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>

                          <div className="form-group">
                            <label for="userpassword">Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>

                          <div className="mt-3">
                            <button
                              type="submit"
                              className="btn btn-custom btn-block"
                              disabled={loading}
                            >
                              Sign Up
                            </button>
                          </div>

                          <div className="mt-4 mb-0 text-center">
                            <Link to="/login" className="text-dark">
                              <i className="mdi mdi-lock"></i>Already Have
                              Account?
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
