import React, { useEffect, useState } from "react";
import { useRef } from "react";
import Navbar from "../components/Navbar";
import Process from "../components/Process";
import Testi from "../components/Testi";
import Contact from "../components/Contact";
import FooterLinks from "../components/FooterLinks";
import { useNavigate } from "react-router-dom";
import Chat from "../components/Chat";
import { onValue, ref, db } from "../config/firebase";

const Home = () => {
  const navigate = useNavigate();

  const [comments, setComments] = useState([]);

  const cref = useRef(null);

  const handleClick = () => {
    cref.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    onValue(ref(db, "comments"), (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const propertyValues = Object.values(data);

        setComments(propertyValues);
      }
    });
  }, []);
  return (
    <>
      {/* Navbar Component*/}
      <Navbar activePage="Home" />

      <section id="mainHome">
        <div className="container">
          <div className="row mainRow">
            <div className="col-lg-7 centerCols">
              <div className="w-100">
                <h1 className="mainTitle">
                  We help startups
                  <br />
                  launch their products
                </h1>
                <div className="boxCon">
                  <div className="countBox">
                    <font className="blueCount">11+</font>
                    <font className="smallText">
                      Years of
                      <br />
                      Experience
                    </font>
                  </div>
                  <div className="countBox">
                    <font className="blueCount">360+</font>
                    <font className="smallText">
                      Successful
                      <br />
                      Projects
                    </font>
                  </div>
                </div>
                <button className="btn inquiryBtn" onClick={handleClick}>
                  Make Inquiry
                </button>
              </div>
            </div>
            <div className="col-lg-5 centerCols">
              <img className="mainGif" src="images/Gifs/homeMain.gif"></img>
            </div>
          </div>
        </div>
        <div id="circle1"></div>
        <div id="circle2"></div>
      </section>

      {/* Process Component*/}
      <Process />

      {/* Testi Component*/}
      <Testi comments={comments} />

      {/* Contact Component*/}
      <div ref={cref}></div>
      <Contact />

      {/* Features Component*/}
      {/* <Features /> */}

      {/* Descriptions Component*/}
      {/* <Descriptions /> */}

      {/* Team Component*/}
      {/* <Team /> */}

      {/* SocialMedia Component*/}
      {/* <SocialMedia /> */}

      {/* Footer Component*/}
      {/* <Footer /> */}

      {/* FooterLinks Component*/}
      <FooterLinks />
      {/* Chat Component*/}
      <Chat />
    </>
  );
};

export default Home;
