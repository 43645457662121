import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { db, set, ref, onValue } from "../config/firebase";
import { auth } from "../config/firebase";
import axios from "axios";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  async function logIn(email, password) {
    return await signInWithEmailAndPassword(auth, email, password).then(
      (data) => {
        const userRef = ref(db, "users/" + data.user.uid);

        onValue(userRef, (snapshot) => {
          if (snapshot.val()) {
            setUser(snapshot.val());
          } else {
            logOut();
          }
        });
      }
    );
  }
  async function createAccount(email, password, firstname, lastname) {
    return await createUserWithEmailAndPassword(auth, email, password).then(
      (data) => {
        const userRef = ref(db, "users/" + data.user.uid);
        set(userRef, {
          email: data.user.email,
          firstname: firstname,
          lastname: lastname,
          uid: data.user.uid,
        }).then(() => {
          axios.post(
            `https://us-central1-webtech-services.cloudfunctions.net/webApi/api/v1/sendemail`,
            {
              firstname: firstname,
              lastname: lastname,
              email: email,
              url: window.location.href + "login",
            }
          );
        });
        alert("Account Created Successfully");
      }
    );
  }
  function logOut() {
    return signOut(auth);
  }

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      if (currentuser) {
        const userRef = ref(db, "users/" + currentuser.uid);

        onValue(userRef, (snapshot) => {
          if (snapshot.exists()) {
            setUser(snapshot.val());
            console.log(snapshot.val());

            setLoading(false);
          } else {
            logOut();
            setLoading(false);
          }
        });
      } else {
        logOut();
        setLoading(false);
        setUser({});
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  return (
    <userAuthContext.Provider
      value={{
        user,
        logIn,
        createAccount,
        loading,
        logOut,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
