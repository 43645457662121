import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import FooterLinks from "../components/FooterLinks";
import AboutUs from "../components/AboutUs";
import EventBox from "../components/EventBox";
import Contact from "../components/Contact";
import Chat from "../components/Chat";
import Gallery from "react-photo-gallery";
import { onValue, ref, db } from "../config/firebase"; // fixed import for db

const GalleryImages = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      const unsubscribe = onValue(
        ref(db, "galleryimages"),
        async (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const propertyValues = Object.values(data);

            console.log(propertyValues);
            setGalleryImages(propertyValues);

            // Process the gallery images into photos
            const photosData = await Promise.all(
              propertyValues.map(
                (image) =>
                  new Promise((resolve) => {
                    const img = new window.Image(); // Use window.Image to avoid React-Bootstrap conflict
                    img.src = image.thumbnail;
                    img.onload = () => {
                      resolve({
                        src: image.thumbnail,
                        width: img.width,
                        height: img.height,
                      });
                    };
                  })
              )
            );

            setPhotos(photosData);
          }
        }
      );

      return () => {
        unsubscribe();
      };
    };

    fetchGalleryImages().catch((error) =>
      console.error("Error fetching images:", error)
    );
  }, []);

  return (
    <>
      {/* Navbar Component*/}
      <Navbar activePage="Gallery" />

      <section id="mainHome" className="mainHomeAbout">
        <div className="container">
          <div className="row mainRow mainRowAbout">
            <div className="col-lg-6 centerCols">
              <div className="w-100">
                <h1 className="mainTitle">Gallery</h1>
                <p className="mainPara">
                  Welcome to the Gallery, a showcase of my work as a developer.
                  Here you'll find a collection of projects that highlight my
                  skills, creativity, and dedication to crafting meaningful
                  solutions. From web applications to mobile platforms, each
                  project reflects my journey of growth and problem-solving in
                  the world of development. Feel free to explore the visuals and
                  dive into the details of the work I've done. I hope this
                  portfolio gives you a glimpse of my passion for coding and
                  innovation.
                </p>
              </div>
            </div>
            <div className="col-lg-6 centerCols centerColsAbout">
              <img
                className="mainGifAbout"
                src="images/Gifs/eventsGif.gif"
                alt="Gallery"
              ></img>
            </div>
          </div>
        </div>
        <div id="circle1"></div>
        <div id="circle2"></div>
      </section>

      {/* Gallery Component*/}
      <section className="section">
        <div className="container">
          <Gallery photos={photos} />
        </div>
      </section>

      {/* FooterLinks Component*/}
      <FooterLinks />

      {/* Chat Component*/}
      <Chat />
    </>
  );
};

export default GalleryImages;
