import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import logos from "../images/logos.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { logIn, loading } = useUserAuth();
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    await logIn(email, password);
    navigate("/");
  };
  return (
    <>
      <div className="account-home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="mdi mdi-home h1"></i>
        </Link>
      </div>

      <section className="bg-account-pages height-100vh">
        <div className="display-table">
          <div className="display-table-cell">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="card account-card">
                    <div className="card-body">
                      <div className="text-center mt-3">
                        <h3 className="font-weight-bold">
                          <Link
                            to="/login"
                            className="text-dark text-uppercase account-pages-logo"
                          >
                            <img src={logos} style={{ height: "100px" }} />
                          </Link>
                        </h3>
                        <p className="text-muted">Sign in to continue.</p>
                      </div>
                      <div className="p-3">
                        <form onSubmit={submit}>
                          <div className="form-group">
                            <label for="username">Email Address</label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              placeholder="Enter Email Address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>

                          <div className="form-group">
                            <label for="userpassword">Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>

                          <div className="mt-3">
                            <button
                              type="submit"
                              className="btn btn-custom btn-block"
                              disabled={loading}
                            >
                              Log In
                            </button>
                          </div>

                          <div className="mt-4 mb-0 text-center">
                            <Link to="/signup" className="text-dark">
                              <i className="mdi mdi-lock"></i>Create New Account
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
