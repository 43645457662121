import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onValue, ref, db } from "../config/firebase";

const Services = () => {
  const navigate = useNavigate();

  const detail = (key) => {
    navigate("/servicedetails", {
      state: {
        key: key,
      },
    });
  };

  const [services, setServices] = useState([]);

  useEffect(() => {
    onValue(ref(db, "services"), (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const propertyValues = Object.values(data);

        setServices(propertyValues);
      }
    });
  }, []);
  return (
    <section className="section" id="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <p className="section-title-small text-center">LET'S EXPLORE</p>
            <h1 className="section-title-main text-center">Our Services</h1>
            <p className="section-subtitle text-light text-center padding-t-30 font-secondary">
              We craft digital, graphic and dimensional thinking, to create
              category leading brand experiences that have meaning and add a
              value for our clients.
            </p>
          </div>
        </div>
        <div className="row margin-t-30">
          {services.map((service) => (
            <div className="col-md-6 col-lg-4 margin-t-20 text-center">
              <div className="services-box text-center hover-effect">
                {service.title == "Web Development" ? (
                  <img
                    src="images/Gifs/webDev.gif"
                    className="serviceImage"
                  ></img>
                ) : service.title == "Web Designing" ? (
                  <img
                    src="images/Gifs/webDesign.gif"
                    className="serviceImage"
                  ></img>
                ) : service.title == "Mobile Application Development" ? (
                  <img
                    src="images/Gifs/mobileDev.gif"
                    className="serviceImage"
                  ></img>
                ) : null}
                <h4 className="serviceTitle padding-t-15">{service.title}</h4>
                {/* <p className="padding-t-15 text-muted">
                  {service.shortDescription}
                </p> */}
              </div>
              <button
                className="btn viewServiceBtn"
                onClick={() => detail(service.key)}
              >
                View Details
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Services;
