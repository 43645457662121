import React from "react";
import Navbar from "../components/Navbar";
import FooterLinks from "../components/FooterLinks";
import AboutUs from "../components/AboutUs";
import Contact from "../components/Contact";
import Chat from "../components/Chat";

const About = () => {
  return (
    <>
    {/* Navbar Component*/}
    <Navbar activePage = "About" />
    <section id="mainHome" className="mainHomeAbout">
      <div className="container">
        <div className="row mainRow mainRowAbout">
          <div className="col-lg-6 centerCols">
            <div className="w-100">
            <h1 className="mainTitle">Introducing you <br/>Who am I</h1>
            <p className="mainPara">
            I’m a passionate full-stack web developer and designer! As someone who’s always enjoyed exploring her creative and logical side, web developing and designing allows me to get the best of both worlds.<br/><br/>It’s simple, I use my creative side to design and my keen eye for detail and logic to code! As a bonus, being great at both aspects allows me to ensure that no detail is lost in translation.
            </p>
            </div>
          </div>
          <div className="col-lg-6 centerCols centerColsAbout">
            <img className="mainGifAbout" src="images/aboutMain.png"></img>
          </div>
        </div>
      </div>
      <div id="circle1"></div>
      <div id="circle2"></div>
    </section>


    {/* AboutUs Component*/}
    <AboutUs />

    {/* Contact Component*/}
    <Contact />

    {/* FooterLinks Component*/}
    <FooterLinks />
    
    {/* Chat Component*/}
    <Chat />
    </>
  );
};

export default About;
